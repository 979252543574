<template>
	<div class="note" :class="{ disabled: disabled }">
		<div class="title" :class="{ edit: mode === 'edit' }">
      <img src="@/assets/icons/note-yellow.svg" alt="">
      <span>{{ $t('ai_remark')/*備註說明*/ }}</span>
		</div>
    <textarea
      class="content"
      :class="{ edit: mode === 'edit' }"
      :readonly="mode !== 'edit'"
      v-model.trim="remarkData"
    >
    </textarea>
	</div>
</template>

<script>
export default {
  name: 'Note',
  props: [ 'value', 'mode', 'disabled' ],
  data() {
    return {
    }
  },
  computed: {
    remarkData: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.note {
  width: 100%;
  height: 100%;
  &.disabled {
    pointer-events: none;
    opacity: 0.2;
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    color: #FFE99F;
    background: #4A5C7833;
    border-radius: 8px 8px 0 0;
    padding: 10px 12px;
    box-sizing: border-box;
    &.edit {
      color: #ffffff;
      font-weight: normal;
      
      border: 1px solid #9D9D9D;
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(283deg) brightness(104%) contrast(103%); 
      }
    }
    img {
      margin-right: 8px;
    }
  }

  .content{
    width: 100%;
    height: calc(100% - 56px);
    padding: 6px 6px 12px 12px;
    overflow-y: overlay;
    font-size: 24px;
    line-height: 36px;
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
    outline: none;
    border: none;
    resize: none;
  }
}

textarea {
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  background: #4A5C7833;
  cursor: default;
  &.edit {
    color: #191919;
    background: #ffffff;
    border: 1px solid #9D9D9D;
    outline: none;
  }
}

textarea[read-only] {
  color: #ffffff;
  background: #4A5C7833;
  cursor: default;
}

[contenteditable='false'] {
  color: #ffffff;
  background: #4A5C7833;
}

[contenteditable='true'] {
  color: #191919;
  background: #ffffff;
  outline: none;
}
</style>